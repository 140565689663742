<template>
  <div>
    <coupon-filters
      class="s-mb-3"
      :options="filterOptions"
      v-model="filter"
    ></coupon-filters>
    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <coupon-card
        v-else-if="!showLoading && coupons.length > 0"
        v-for="item in filteredCoupons[filter]"
        :key="item.id"
        :coupon="item"
        :buttonText="item.status == 'applied_and_not_yet' ? '尚未開始' : '查看'"
        @exchange="showCouponModal"
      >
      </coupon-card>
      <coupon-empty v-else />
    </div>
    <coupon-modal ref="couponModal" :coupon="selectedCoupon" @confirmed="refresh"/>
  </div>
</template>

<script>
import CouponCard from "@/pages/Dashboard/Coupon/CouponCard.vue"
import CouponFilters from "@/pages/Dashboard/Coupon/CouponFilters.vue"
import CouponModal from "@/components/Page/Liff/Coupon/CouponModal.vue"
import couponApi from '@/apis/liff/v2/coupon'
import CouponEmpty from "@/pages/Dashboard/Coupon/CouponEmpty.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";
import { isAfter, addDays } from "date-fns";

export default {
  components: { CouponFilters, CouponCard, CouponModal, CouponEmpty, Loading },
  data() {
    return {
      showLoading: true,
      filter: "all",
      coupons: [],
      selectedCoupon: {},
    }
  },

  computed: {
    filterOptions() {
      return [
        {
          key: "all",
          text: `${"全部 (" + this.coupons.length + ")"}`,
        },
        {
          key: "upcoming",
          text: `${"即將到期 (" + this.filteredCoupons.upcoming.length + ")"}`,
        },
        {
          key: "notYet",
          text: `${"尚未開始 (" + this.filteredCoupons.notYet.length + ")"}`,
        },
      ]
    },
    filteredCoupons() {
      const today = new Date().toJSON().slice(0, 10)
      const comingDay = addDays(new Date(today), 7)

      return {
        all: this.coupons,
        upcoming: this.coupons.filter((c) => {
          if (c.status === "upcoming") {
            return true
          }
          // 因為我的優惠卷會顯示五分鐘內使用過的優惠卷，此時優惠卷的狀態會是 redeemed，非 upcoming
          // 所以要額外判斷 end_at 是否在今天到七天後之間
          return c.end_at && isAfter(comingDay, new Date(c.end_at))
        }),
        notYet: this.coupons.filter((c) => c.status === "applied_and_not_yet"),
      }
    },
  },

  mounted() {
    this.fetchCoupon()
  },

  methods: {
    async fetchCoupon() {
      this.showLoading = true
      await this.doFetchCoupon()
      this.showLoading = false
    },
    async doFetchCoupon() {
      this.coupons = []

      let coupons = await couponApi.getCoupons({
        status: 'redeemed_and_can_reuse',
        sort_by: 'apply_at',
      })
      this.coupons.push(...coupons)

      coupons = await couponApi.getCoupons({
        status: 'applied',
        sort_by: 'apply_at',
      })
      this.coupons.push(...coupons)

      this.coupons.forEach((coupon) => {
        coupon.name = coupon.redeem_code_bundle.name
        coupon.is_enabled = coupon.status === 'applied_and_not_yet' ? false : true
      })
    },
    showCouponModal(coupon) {
      this.selectedCoupon = coupon
      this.$refs.couponModal.open()
    },
    refresh() {
      this.selectedCoupon = {}
      this.fetchCoupon()
    },
  },
}
</script>

<style lang="scss" scoped></style>
