<template>
  <div class="coupon-show">
    <Loading v-if="showLoading" loading-message="載入中..." />
    <div v-else class="coupon-show__panel">
      <coupon
        v-if="coupon"
        ref="couponModal"
        :coupon="coupon"
        @confirmed="refresh"
      />
      <coupon-empty v-else />
    </div>
  </div>
</template>

<script>
import Coupon from "@/components/Page/Liff/Coupon/Coupon.vue";
import CouponEmpty from "@/pages/Dashboard/Coupon/CouponEmpty.vue";
import couponApi from '@/apis/liff/v2/coupon'
import Loading from "@/components/Page/Liff/Shared/Loading";

export default {
  components: { Coupon, CouponEmpty, Loading },
  data() {
    return {
      showLoading: true,
      coupon: null,
    };
  },

  async mounted() {
    await this.fetchCoupon();
  },

  methods: {
    async fetchCoupon() {
      this.showLoading = true;
      try {
        this.coupon = await couponApi.getCoupon(
          this.$route.params.id
        );
      } catch (e) {
        console.error(e)
        this.coupon = null;
      }
      this.showLoading = false;
    },
    refresh() {
      this.fetchCoupon();
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-show {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background-color: var(--s-gray-lighter);
  display: flex;
  align-items: center;
  padding: 12px;
  z-index: 50;
  color: #636366;
  max-width: 600px;
  margin: auto;
}

.coupon-show__panel {
  position: relative;
  width: 100%;
  max-height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-image: url(./bg-coupon-bottom.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 24px;

  .coupon {
    overflow-y: scroll;
  }
}
</style>
