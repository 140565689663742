import { render, staticRenderFns } from "./CouponShow.vue?vue&type=template&id=af931b62&scoped=true&"
import script from "./CouponShow.vue?vue&type=script&lang=js&"
export * from "./CouponShow.vue?vue&type=script&lang=js&"
import style0 from "./CouponShow.vue?vue&type=style&index=0&id=af931b62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af931b62",
  null
  
)

export default component.exports