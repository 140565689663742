<template>
  <div class="popup" v-if="shown">
    <div class="popup__backdrop" />

    <div class="popup__panel">
      <div class="popup__close" @click="close">
        <i class="popup__close--icon" aria-hidden="true"></i>
      </div>
      <div class="popup__header">
        <span class="popup__header-title">
          <slot v-if="$slots.title" name="title"></slot>
          <template v-else>{{ title }}</template>
        </span>
      </div>
      <div class="popup__body" :class="scrolling ? 'scrolling' : ''">
        <slot />
      </div>
      <div class="popup__actions" v-if="!noActions">
        <slot name="actions" :close="close">
          <button class="s-btn s-btn-primary" type="button" @click="close">
            {{ buttonText }}
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    noActions: {
      type: Boolean,
    },
    buttonText: {
      type: String,
      default: "確定",
    },
    scrolling: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    shown: false,
    closeCallback: null,
  }),
  methods: {
    open(callback = null) {
      this.closeCallback = callback;
      this.shown = true;
    },
    close() {
      this.shown = false;

      if (typeof this.closeCallback === "function") {
        this.closeCallback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup,
.popup__backdrop {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.popup__backdrop {
  background-color: var(--s-black);
  opacity: 0.3;
}

.popup {
  display: flex;
  align-items: center;
  padding: 12px;
  z-index: 50;
  color: #636366;
  max-width: 600px;
  margin: auto;
}

.popup__panel {
  position: relative;
  background-color: var(--s-white);
  box-shadow: 0px 2px 4px 0px #0000001a;
  width: 100%;
  max-height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 32px 16px 24px;
}

.scrolling {
  overflow-y: scroll;
  height: 60vh;
}

.popup__header {
  margin-bottom: 24px;
}
.popup__header-title {
  font-family: PingFang TC;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  color: #2c2c2e;
}

.popup__close {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: var(--s-white);
  border-radius: 50%;
}

.popup__close--icon:before,
.popup__close--icon:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 2px;
  background-color: var(--s-black);
}

.popup__close--icon:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.popup__close--icon:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.popup__actions {
  button {
    height: 48px;
  }
}
</style>
