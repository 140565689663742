<template>
  <div class="coupon-modal">
    <popup ref="popup" :noActions="true" :scrolling="true">
      <coupon v-if="coupon" :coupon="coupon" @confirmed="confirmed" />
    </popup>
  </div>
</template>

<script>
import Popup from "@/components/Page/Liff/Shared/Popup.vue";
import Coupon from "./Coupon";

export default {
  components: { Popup, Coupon },
  props: {
    coupon: {
      type: Object,
      default: () => {
        return {
          code: null,
        };
      },
    },
  },
  methods: {
    open() {
      this.$refs.popup.open();
    },
    confirmed() {
      this.$refs.popup.close();
      this.$emit("confirmed");
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-modal {
  ::v-deep .popup__header {
    margin-bottom: 0px;
  }

  ::v-deep .popup__panel {
    background-color: transparent;
    background-image: url(./bg-coupon-bottom.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    padding: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-bottom: 3vh;
    box-shadow: none;
    max-height: 80vh;
    margin-top: 40px;
  }

  ::v-deep .popup__body.scrolling {
    height: auto;
  }
}
</style>
